var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-wrapper"},[_c('MyImage',{staticClass:"logo",attrs:{"imageUrl":"https://images.ctfassets.net/pl05rcmbdryr/gRvoOsVd6G9t9xUwXgrUT/8202bd92278ec1ae96a1f0b797139cf2/Logo.png","widthList":[100, 200],"alt":"logo"},on:{"click":_vm.toHome}}),(_vm.isTrial)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.launchTrial}},[_vm._v(" "+_vm._s(_vm.$t("nav-menu.button.trial"))+" ")]):_vm._e(),(!_vm.toggle)?_c('img',{staticClass:"menu-icon",attrs:{"src":require("./assets/images/menu.png")},on:{"click":function($event){return _vm.handleToggle(true)}}}):_vm._e(),(_vm.toggle)?_c('img',{staticClass:"menu-icon close",attrs:{"src":require("./assets/images/close.png")},on:{"click":function($event){return _vm.handleToggle(false)}}}):_vm._e()],1),_c('div',{staticClass:"drawer",class:{
            drawer__open: !_vm.toggle,
        }},[_c('div',{staticClass:"drawer-item",staticStyle:{"padding-top":"64px"},on:{"click":_vm.launchAbout}},[_vm._v(" "+_vm._s(_vm.$t("nav-menu.about")))]),_c('div',{staticClass:"menu"},[_c('div',{staticClass:"menu-item",on:{"click":function($event){return _vm.handleHeaderClick(1)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("nav-menu.why"))+" ")]),_c('img',{staticClass:"icon-arrow-down",class:{
                        'is-active': _vm.activeNames === 1,
                    },attrs:{"src":require("./assets/images/down.png")}})]),(_vm.activeNames === 1)?_c('menu-item',{attrs:{"deviceType":"mobile","list":_vm.listWhy}}):_vm._e(),_c('div',{staticClass:"menu-item",on:{"click":function($event){return _vm.handleHeaderClick(2)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("nav-menu.programs"))+" ")]),_c('img',{staticClass:"icon-arrow-down",class:{
                        'is-active': _vm.activeNames === 2,
                    },attrs:{"src":require("./assets/images/down.png")}})]),(_vm.activeNames === 2)?_c('menu-item',{attrs:{"deviceType":"mobile","list":_vm.listPrograms}}):_vm._e(),_c('div',{staticClass:"menu-item",on:{"click":function($event){return _vm.handleHeaderClick(3)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("nav-menu.resources"))+" ")]),_c('img',{staticClass:"icon-arrow-down",class:{
                        'is-active': _vm.activeNames === 3,
                    },attrs:{"src":require("./assets/images/down.png")}})]),(_vm.activeNames === 3)?_c('menu-item',{attrs:{"deviceType":"mobile","list":_vm.listResources}}):_vm._e()],1),_c('div',{staticClass:"drawer-item-site",on:{"click":_vm.launchSite}},[_vm._v(" "+_vm._s(_vm.$t("nav-menu.site")))]),_c('div',{staticClass:"drawer-item",staticStyle:{"margin-top":"64px"},on:{"click":_vm.launchTerms}},[_vm._v(" "+_vm._s(_vm.$t("nav-menu.terms")))]),_c('div',{staticClass:"drawer-item",staticStyle:{"margin-top":"38px","padding-bottom":"150px"},on:{"click":_vm.launchPrivacy}},[_vm._v(" "+_vm._s(_vm.$t("nav-menu.privacy")))])])])}
var staticRenderFns = []

export { render, staticRenderFns }